import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupService } from './services/popup.service';
import { NotificationModule } from '@progress/kendo-angular-notification';


@NgModule({
  imports: [
    CommonModule,
    NotificationModule
  ],
  providers: [
    PopupService
  ]
})
export class PopupModule { }
